
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navbar, Nav } from "react-bootstrap";

function NavBar() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const checkScreenSize = () => {
    if (window.innerWidth >= 768) {
      setIsMobileNavOpen(false);
    }
  };

  useEffect(() => {
    // Initial check
    checkScreenSize();

    // Event listener for resizing
    window.addEventListener("resize", checkScreenSize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  return (
    <Navbar
      expand="md"
      onToggle={toggleMobileNav}
      expanded={isMobileNavOpen}
      className={isMobileNavOpen ? " custom-color" : "bg-transparent"}
      
    >
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            alt="el-safra logo"
            src="./images/logo.png"
            width="130"
            height="50"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={isMobileNavOpen ? "custom-nav" : "bg-transparent"}>
            <Nav.Link href="#about-us" className="me-3 "> من نحن</Nav.Link>
           
            <Nav.Link href="#why-us" className="me-3">لماذا السفره </Nav.Link>
            <Nav.Link href="#pricing-cards" className="me-3">خطط الإشتراك الشهريه </Nav.Link>
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
