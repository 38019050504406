import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import AboutUs from './components/aboutUs';
import Footer from './components/footer';
import Hero from './components/hero';
import OurServices from './components/ourServices';
import Pricing from './components/pricing';
import SubscriptionForm from './components/subscriptionForm';
import WhyUs from './components/whyUs';
import { SuccessMessage, FailureMessage } from './components/paymentMeassage';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/success">
            <SuccessMessage />
          </Route>
          <Route path="/failure">
            <FailureMessage />
          </Route>
          <Route path="/">
            <>
              <Hero />
              <AboutUs />
              <OurServices />
              <WhyUs />
              <Pricing />
              <SubscriptionForm />
              <Footer />
            </>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
