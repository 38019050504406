import {
  BookOpen,
  UsersThree,
  ChalkboardTeacher,
  HandsClapping,
  Eye,
  Star,
} from "phosphor-react";
function WhyUs() {
  return (
    <section id="why-us" className="py-5 ">
      <div className="container">
        <h2 className="mb-5 display-5">لماذا السفره ؟ </h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12  mb-4">
              <div className="why-us-items ">
                <BookOpen size={40} color="#014E4E" weight="bold" />
                <h3 className="mt-3">تعلم معنا بسهولة </h3>
                <p>
                حيث نقدم تجربة تعلم ممتعة وسهلة للقرآن الكريم عبر جميع منصات التواصل الاجتماعي مثل واتساب، تليجرام، ويوتيوب. كما نقدم خدماتنا عبر التطبيقات الذكية، مثل تطبيق أكاديمية إتقان الإلكترونية، وتطبيق "بالقرآن نحيا"، وتطبيق المنبه القرآني.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
              <div className="why-us-items ">
                <UsersThree size={40} color="#014E4E" weight="bold" />
                <h3 className="mt-3">فريق محترف</h3>
                <p>
                لدينا فريق محترف من مشرفين ومشرفات في الإدارة التعليمية. كما يتضمن فريقنا مشرفين ومشرفات تعليميين يسعون جاهدين لتقديم الدعم الفني والتعليمي للطلاب والطالبات، وكذلك للمعلمين والمعلمات
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
              <div className="why-us-items ">
                <ChalkboardTeacher size={40} color="#014E4E" weight="bold" />
                <h3 className="mt-3">نقدم برامج تفاعلية </h3>
                <p>
                تُعَدُّ البرامج التفاعلية عنصرًا أساسيًا في نجاح الأكاديمية في أداء رسالتها في تعليم القرآن الكريم بمفهومه الواسع، علمًا وعملاً اقتداءً بسلف الأمة. يتم من خلال هذا الفريق تنفيذ الدورات العلمية والبرامج التفاعلية، بالإضافة إلى إدارة قنوات التليجرام والواتساب واليوتيوب، وكذلك غرف الزووم.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
              <div className="why-us-items">
                <HandsClapping size={40} color="#014E4E" weight="bold" />
                <h3 className="mt-3"> مجتمع تعلم مشجع</h3>
                <p>
                إنضم إلى مجتمعنا الداعم الذي يشجع على تبادل الخبرات والتفاعل. يمكنك المشاركة في مناقشات والتواصل مع طلاب آخرين ملهمين.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
              <div className="why-us-items ">
                <Star size={40} color="#014E4E" weight="bold" />
                <h3 className="mt-3"> الإلتزام بالجودة</h3>
                <p>
                لدينا فريق خبير يتواجد في الإدارة التعليمية، حيث يعمل على إحصاء الإنجازات، واكتشاف المشكلات والأخطاء، وفهم أسبابها، ثم العمل على إيجاد الحلول وتقديمها لمشرف الإدارة من خلال تقارير دورية مستمرة.

                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
              <div className="why-us-items ">
                <Eye size={40} color="#014E4E" weight="bold" />
                <h3 className="mt-3"> الإشراف التربوي </h3>
                <p>
                  ويتعلق هذا بكل ما يتعلق بتطوير المعلمين والمشرفين تربويًا، حيث يعمل عدد من المشرفين التربويين الذين يركزون جهودهم على التواصل مع المعلمين والمشرفين، وتقديم التوجيهات والنصائح التطويرية لهم.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
