import {
  CurrencyDollar,
  LightbulbFilament,
  CheckCircle,
  Star,
  Crown,
  CurrencyGbp,
} from "phosphor-react";
function Pricing() {
  return (
    <section id="pricing-cards" className="py-5">
      <div className="container">
        <h2 className="mb-5 display-5">خطط الإشتراك الشهريه </h2>
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 ">
              <div className="price-card text-center d-flex justify-content-center align-items-center rounded mb-5 ">
                <div className="w-100">
                  <div className="price-card-header ">
                    <LightbulbFilament
                      size={60}
                      color="#F6F5E3"
                      className="mb-3"
                    />
                    <h3 className="h3">الباقة الإبتدائية</h3>
                  </div>
                  <div className=" price-card-price   ">
                    <h6 className="display-2 d-inline-block">
                      600
                      <span style={{ fontSize: "0.3em" }}>EGP</span>
                    </h6>

                    <p>كل شهر </p>
                  </div>
                  <div className="m-auto w-75 line"></div>
                  <div className="price-card-body mt-4 ">
                    <ul className="list-unstyled ">
                      <li className="mb-2">
                        <CheckCircle size={25} color="#F6F5E3" />{" "}
                        <b>عدد الدروس :</b> 4 دروس
                      </li>
                      <li className="mb-2">
                        <CheckCircle size={25} color="#F6F5E3" />{" "}
                        <b>عدد الطلاب :</b> &nbsp;1 &nbsp; طالب
                      </li>
                    </ul>
                  </div>
                  <div className="price-card-footer ">
                    <a
                      href="#subscription-form"
                      className="btn price-btn px-5 rounded-pill "
                    >
                      إشترك الآن
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="price-card text-center d-flex justify-content-center align-items-center rounded mb-5 ">
                <div className="w-100">
                  <div className="price-card-header ">
                    <Star size={60} color="#F6F5E3" className="mb-3" />
                    <h3 className="h3"> الباقة المتميزة </h3>
                  </div>
                  <div className=" price-card-price  ">
                    <h6 className="display-2  d-inline-block">
                      850
                      <span style={{ fontSize: "0.3em" }}>EGP</span>
                    </h6>

                    <p>كل شهر </p>
                  </div>
                  <div className="m-auto w-75 line"></div>
                  <div className="price-card-body mt-4 ">
                    <ul className="list-unstyled ">
                      <li className="mb-2">
                        <CheckCircle size={25} color="#F6F5E3" />{" "}
                        <b>عدد الدروس :</b> 8 دروس
                      </li>
                      <li className="mb-2">
                        <CheckCircle size={25} color="#F6F5E3" />{" "}
                        <b>عدد الطلاب :</b> &nbsp;1 &nbsp; طالب
                      </li>
                    </ul>
                  </div>
                  <div className="price-card-footer ">
                    <a
                      href="#subscription-form"
                      className="btn price-btn px-5 rounded-pill "
                    >
                      إشترك الآن
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="price-card text-center d-flex justify-content-center align-items-center rounded mb-5 ">
                <div className="w-100">
                  <div className="price-card-header ">
                    <Crown size={60} color="#F6F5E3" className="mb-3" />
                    <h3 className="h3">الباقة النخبوية </h3>
                  </div>
                  <div className=" price-card-price">
                    <h6 className="display-2  d-inline-block">
                      1350
                      <span style={{ fontSize: "0.3em" }}>EGP</span>
                    </h6>

                    <p>كل شهر </p>
                  </div>
                  <div className="m-auto w-75 line"></div>
                  <div className="price-card-body mt-4 ">
                    <ul className="list-unstyled ">
                      <li className="mb-2">
                        <CheckCircle size={25} color="#F6F5E3" />{" "}
                        <b>عدد الدروس :</b> 12 دروس
                      </li>
                      <li className="mb-2">
                        <CheckCircle size={25} color="#F6F5E3" />{" "}
                        <b>عدد الطلاب :</b> &nbsp;1 &nbsp; طالب
                      </li>
                    </ul>
                  </div>
                  <div className="price-card-footer ">
                    <a
                      href="#subscription-form"
                      className="btn price-btn px-5 rounded-pill "
                    >
                      إشترك الآن
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
