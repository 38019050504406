import {
  BookOpen,
  CheckCircle,
  Ear,
  Handshake,
  MicrophoneStage,
  Pen,
} from "phosphor-react";

function OurServices() {
  const mathArray = [
    "الأعداد",
    "الأعداد الأولية",
    "جمع و طرح العدد الصحيح",
    "ضرب العدد الصحيح وخواصه",
    "قسمة العدد الصحيح",
    "الكسور",
    "الأرقام العشرية",
    "مقارنة الكسور",
    "الأسس",
    "خاصية التوزيع",
    "ترتيب العمليات الحسابية",
    "خاصيتى التبديل والترابط",
  ];

  return (
    <section id="our-services" className="py-5 ">
      <div className="container">
        <h2 className="mb-5 display-4 me-4">
          {" "}
          خدماتنا <Handshake size={62} />{" "}
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-6  d-flex justify-content-center flex-column ">
              <h3 className="mb-4">تعليم مادة الرياضيات</h3>
              <p className="lead">
                في أكاديمية السفرة للتعليم عن بعد مهمتنا مساعده الطلاب الذين
                يواجهون مشاكل في الرياضيات وفي فهم وحل المعادلات والتعامل مع
                الاعداد للصفوف من خامس الى تاسع الى احتراف الرياضيات مع معلمين
                متميزين للتعليم اون لاين لمادة الرياضيات إذا كان الإستثمار في
                ابنائنا مكلفا فانت تجهل تكلفة  اهمالهم
              </p>
              <p className="lead">
                في اكاديميتنا السفرة للتعليم عن بعد سوف تتعلم خصائص الأرقام
                والرياضيات الأساسية والجبر والهندسة من الصف الأول إلى الصف
                الثامن
              </p>

              <h4 className="mb-4">ايضا سوف تتعلم الأتي</h4>

              <div className="row">
                {mathArray.map((sentence) => {
                  return (
                    <div key={sentence} className=" col-md-6">
                      <div className="d-flex">
                        <CheckCircle
                          size={30}
                          className="ms-2"
                          color="#F6F5E3"
                          weight="light"
                        />
                        <p className="lead">{sentence}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center flex-column ">
              <div className=" ">
                <img
                  src="./images/math-service.jpg"
                  className="our-services-img object-fit-fill rounded-4 "
                  alt="xx"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 second-row">
            <div className="col-lg-6">
              <div className=" ">
                <img
                  src="./images/english-service.jpg"
                  className="our-services-img object-fit-fill rounded-4 "
                  alt="xx"
                  srcset=""
                />
              </div>
            </div>
            <div className="col-lg-6  d-flex justify-content-center flex-column ">
              <h3 className="mb-4">تعليم مادة اللغة الإنجليزية</h3>
              <p className="lead">
                في أكاديمية السفرة للتعليم عن بعد مهمتنا مساعده الطلاب الذين
                يواجهون مشاكل في اللغة الإنجليزية وذلك عن طريق منهجيتنا الخاصة
                المميزة . حيث ان في كل جلسة سيتعلم طفلك العديد من الكلمات المهمة
                والمثيرة، وسيستكشف مواضيع مختلفة لتحفيز فضوله وتوسيع معرفته.{" "}
                <br />
                ولضمان استمرار انشغاله ومتعته، نقدم العديد من الألعاب التعليمية
                التي تجعل الدرس ممتعًا ومثيرًا، وتجعل طفلك يشعر بالراحة
                والاستمتاع خلال الحصة.
              </p>

              <h4 className="mb-4">ايضا سوف تتعلم الأتي</h4>

              <div className="row">
                <div className="col-4">
                  <div className="d-flex">
                    <BookOpen
                      size={35}
                      className="ms-2"
                      color="#F6F5E3"
                      weight="light"
                    />
                    <p className="lead">القراءه</p>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div className="d-flex">
                    <MicrophoneStage
                      size={35}
                      className="ms-2"
                      color="#F6F5E3"
                      weight="light"
                    />
                    <p className="lead">التحدث</p>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div className="d-flex">
                    <Pen
                      size={35}
                      className="ms-2"
                      color="#F6F5E3"
                      weight="light"
                    />
                    <p className="lead">الكتابة</p>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div className="d-flex">
                    <Ear
                      size={35}
                      className="ms-2"
                      color="#F6F5E3"
                      weight="light"
                    />
                    <p className="lead">الإستماع</p>
                  </div>
                </div>
                <div className=" col-md-6">
                  <div className="d-flex">
                    <BookOpen
                      size={35}
                      className="ms-2"
                      color="#F6F5E3"
                      weight="light"
                    />
                    <p className="lead">القواعد النحوية</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurServices;
