import React from "react";
import styled from "@emotion/styled";
import Select from "react-dropdown-select";

function PhoneInput({ setCountryCode }) {
  const options = [
    { label: "مصر (+20)", value: "Egypt", code: "+20" },
    { label: "الجزائر (+213)", value: "Algeria", code: "+213" },
    { label: "أندورا (+376)", value: "Andorra", code: "+376" },
    { label: "أنغولا (+244)", value: "Angola", code: "+244" },
    { label: "أنغويلا (+1264)", value: "Anguilla", code: "+1264" },
    {
      label: "أنتيغوا وبربودا (+1268)",
      value: "Antigua & Barbuda",
      code: "+1268",
    },
    { label: "الأردن (+962)", value: "Jordan", code: "+962" }
,
    { label: "الأرجنتين (+54)", value: "Argentina", code: "+54" },
    { label: "أرمينيا (+374)", value: "Armenia", code: "+374" },
    { label: "أروبا (+297)", value: "Aruba", code: "+297" },
    { label: "أستراليا (+61)", value: "Australia", code: "+61" },
    { label: "النمسا (+43)", value: "Austria", code: "+43" },
    { label: "أذربيجان (+994)", value: "Azerbaijan", code: "+994" },
    { label: "باهاماز (+1242)", value: "Bahamas", code: "+1242" },
    { label: "البحرين (+973)", value: "Bahrain", code: "+973" },
    { label: "بنغلاديش (+880)", value: "Bangladesh", code: "+880" },
    { label: "باربادوس (+1246)", value: "Barbados", code: "+1246" },
    { label: "بيلاروس (+375)", value: "Belarus", code: "+375" },
    { label: "بلجيكا (+32)", value: "Belgium", code: "+32" },
    { label: "بليز (+501)", value: "Belize", code: "+501" },
    { label: "بنين (+229)", value: "Benin", code: "+229" },
    { label: "برمودا (+1441)", value: "Bermuda", code: "+1441" },
    { label: "بوتان (+975)", value: "Bhutan", code: "+975" },
    { label: "بوليفيا (+591)", value: "Bolivia", code: "+591" },
    {
      label: "البوسنة والهرسك (+387)",
      value: "Bosnia Herzegovina",
      code: "+387",
    },
    { label: "بوتسوانا (+267)", value: "Botswana", code: "+267" },
    { label: "البرازيل (+55)", value: "Brazil", code: "+55" },
    { label: "بروناي (+673)", value: "Brunei", code: "+673" },
    { label: "بلغاريا (+359)", value: "Bulgaria", code: "+359" },
    { label: "بوركينا فاسو (+226)", value: "Burkina Faso", code: "+226" },
    { label: "بوروندي (+257)", value: "Burundi", code: "+257" },
    { label: "كمبوديا (+855)", value: "Cambodia", code: "+855" },
    { label: "الكاميرون (+237)", value: "Cameroon", code: "+237" },
    { label: "كندا (+1)", value: "Canada", code: "+1" },
    { label: "كيب فردي (+238)", value: "Cape Verde Islands", code: "+238" },
    { label: "جزر كايمان (+1345)", value: "Cayman Islands", code: "+1345" },
    {
      label: "جمهورية افريقيا الوسطى (+236)",
      value: "Central African Republic",
      code: "+236",
    },
    { label: "تشيلي (+56)", value: "Chile", code: "+56" },
    { label: "الصين (+86)", value: "China", code: "+86" },
    { label: "كولومبيا (+57)", value: "Colombia", code: "+57" },
    { label: "جزر القمر (+269)", value: "Comoros", code: "+269" },
    { label: "الكونغو (+242)", value: "Congo", code: "+242" },
    { label: "جزر كوك (+682)", value: "Cook Islands", code: "+682" },
    { label: "كوستاريكا (+506)", value: "Costa Rica", code: "+506" },
    { label: "كرواتيا (+385)", value: "Croatia", code: "+385" },
    { label: "كوبا (+53)", value: "Cuba", code: "+53" },
    { label: "قبرص شمال (+90392)", value: "Cyprus North", code: "+90392" },
    { label: "قبرص جنوب (+357)", value: "Cyprus South", code: "+357" },
    { label: "جمهورية التشيك (+42)", value: "Czech Republic", code: "+42" },
    { label: "الدنمارك (+45)", value: "Denmark", code: "+45" },
    { label: "جيبوتي (+253)", value: "Djibouti", code: "+253" },
    { label: "دومينيكا (+1809)", value: "Dominica", code: "+1809" },
    {
      label: "جمهورية الدومينيكان (+1809)",
      value: "Dominican Republic",
      code: "+1809",
    },
    { label: "الإكوادور (+593)", value: "Ecuador", code: "+593" },
    { label: "السلفادور (+503)", value: "El Salvador", code: "+503" },
    {
      label: "غينيا الاستوائية (+240)",
      value: "Equatorial Guinea",
      code: "+240",
    },
    { label: "إريتريا (+291)", value: "Eritrea", code: "+291" },
    { label: "إستونيا (+372)", value: "Estonia", code: "+372" },
    { label: "إثيوبيا (+251)", value: "Ethiopia", code: "+251" },
    { label: "جزر فوكلاند (+500)", value: "Falkland Islands", code: "+500" },
    { label: "جزر فارو (+298)", value: "Faroe Islands", code: "+298" },
    { label: "فيجي (+679)", value: "Fiji", code: "+679" },
    { label: "فنلندا (+358)", value: "Finland", code: "+358" },
    { label: "فرنسا (+33)", value: "France", code: "+33" },
    { label: "جويانا الفرنسية (+594)", value: "French Guiana", code: "+594" },
    {
      label: "بولينيزيا الفرنسية (+689)",
      value: "French Polynesia",
      code: "+689",
    },
    { label: "الغابون (+241)", value: "Gabon", code: "+241" },
    { label: "غامبيا (+220)", value: "Gambia", code: "+220" },
    { label: "جورجيا (+7880)", value: "Georgia", code: "+7880" },
    { label: "ألمانيا (+49)", value: "Germany", code: "+49" },
    { label: "غانا (+233)", value: "Ghana", code: "+233" },
    { label: "جبل طارق (+350)", value: "Gibraltar", code: "+350" },
    { label: "اليونان (+30)", value: "Greece", code: "+30" },
    { label: "جرينلاند (+299)", value: "Greenland", code: "+299" },
    { label: "غرينادا (+1473)", value: "Grenada", code: "+1473" },
    { label: "غوادلوب (+590)", value: "Guadeloupe", code: "+590" },
    { label: "غوام (+671)", value: "Guam", code: "+671" },
    { label: "جواتيمالا (+502)", value: "Guatemala", code: "+502" },
    { label: "جينيا (+224)", value: "Guinea", code: "+224" },
    { label: "غينيا بيساو (+245)", value: "Guinea - Bissau", code: "+245" },
    { label: "غويانا (+592)", value: "Guyana", code: "+592" },
    { label: "هايتي (+509)", value: "Haiti", code: "+509" },
    { label: "هندوراس (+504)", value: "Honduras", code: "+504" },
    { label: "هونغ كونغ (+852)", value: "Hong Kong", code: "+852" },
    { label: "هنغاريا (+36)", value: "Hungary", code: "+36" },
    { label: "آيسلندا (+354)", value: "Iceland", code: "+354" },
    { label: "الهند (+91)", value: "India", code: "+91" },
    { label: "إندونيسيا (+62)", value: "Indonesia", code: "+62" },
    { label: "إيران (+98)", value: "Iran", code: "+98" },
    { label: "العراق (+964)", value: "Iraq", code: "+964" },
    { label: "أيرلندا (+353)", value: "Ireland", code: "+353" },
    { label: "إسرائيل (+972)", value: "Israel", code: "+972" },
    { label: "إيطاليا (+39)", value: "Italy", code: "+39" },
    { label: "جامايكا (+1876)", value: "Jamaica", code: "+1876" },
    { label: "اليابان (+81)", value: "Japan", code: "+81" },
    { label: "جوردانيا (+962)", value: "Jordan", code: "+962" },
    { label: "كازاخستان (+7)", value: "Kazakhstan", code: "+7" },
    { label: "كينيا (+254)", value: "Kenya", code: "+254" },
    { label: "كيريباتي (+686)", value: "Kiribati", code: "+686" },
    { label: "كوريا الشمالية (+850)", value: "Korea North", code: "+850" },
    { label: "كوريا الجنوبية (+82)", value: "Korea South", code: "+82" },
    { label: "الكويت (+965)", value: "Kuwait", code: "+965" },
    { label: "قرغيزستان (+996)", value: "Kyrgyzstan", code: "+996" },
    { label: "لاوس (+856)", value: "Laos", code: "+856" },
    { label: "لاوتو (+266)", value: "Lesotho", code: "+266" },
    { label: "لاتفيا (+371)", value: "Latvia", code: "+371" },
    { label: "لبنان (+961)", value: "Lebanon", code: "+961" },
    { label: "ليبريا (+231)", value: "Liberia", code: "+231" },
    { label: "ليبيا (+218)", value: "Libya", code: "+218" },
    { label: "ليختنشتاين (+417)", value: "Liechtenstein", code: "+417" },
    { label: "ليتوانيا (+370)", value: "Lithuania", code: "+370" },
    { label: "لكسمبورج (+352)", value: "Luxembourg", code: "+352" },
    { label: "الصين الماكاو (+853)", value: "Macao", code: "+853" },
    {
      label: " مقدونيا الشمالية (+853)",
      value: "North Macedonia",
      code: "+389",
    },
    { label: "مدغشقر (+261)", value: "Madagascar", code: "+261" },
    { label: "ملاوي (+265)", value: "Malawi", code: "+265" },
    { label: "ماليزيا (+60)", value: "Malaysia", code: "+60" },
    { label: "الملديف (+960)", value: "Maldives", code: "+960" },
    { label: "مالي (+223)", value: "Mali", code: "+223" },
    { label: "مالطا (+356)", value: "Malta", code: "+356" },
    { label: "جزر مارشال (+692)", value: "Marshall Islands", code: "+692" },
    { label: "مارتينيك (+596)", value: "Martinique", code: "+596" },
    { label: "موريتانيا (+222)", value: "Mauritania", code: "+222" },
    { label: "موريشيوس (+230)", value: "Mauritius", code: "+230" },
    { label: "مايوت (+269)", value: "Mayotte", code: "+269" },
    { label: "المكسيك (+52)", value: "Mexico", code: "+52" },
    { label: "ميكرونيزيا (+691)", value: "Micronesia", code: "+691" },
    { label: "مولدوفا (+373)", value: "Moldova", code: "+373" },
    { label: "موناكو (+377)", value: "Monaco", code: "+377" },
    { label: "منغوليا (+976)", value: "Mongolia", code: "+976" },
    { label: "الجبل الأسود (+382)", value: "Montenegro", code: "+382" },
    { label: "مونتسيرات (+1664)", value: "Montserrat", code: "+1664" },
    { label: "المغرب (+212)", value: "Morocco", code: "+212" },
    { label: "موزمبيق (+258)", value: "Mozambique", code: "+258" },
    { label: "بورما (+95)", value: "Myanmar", code: "+95" },
    { label: "ناميبيا (+264)", value: "Namibia", code: "+264" },
    { label: "ناورو (+674)", value: "Nauru", code: "+674" },
    { label: "نيبال (+977)", value: "Nepal", code: "+977" },
    { label: "هولندا (+31)", value: "Netherlands", code: "+31" },
    { label: "كوراكاو (+599)", value: "Netherlands Antilles", code: "+599" },
    { label: "كاليدونيا الجديدة (+687)", value: "New Caledonia", code: "+687" },
    { label: "نيوزيلندا (+64)", value: "New Zealand", code: "+64" },
    { label: "نيكاراغوا (+505)", value: "Nicaragua", code: "+505" },
    { label: "النيجر (+227)", value: "Niger", code: "+227" },
    { label: "نيجيريا (+234)", value: "Nigeria", code: "+234" },
    { label: "نيوي (+683)", value: "Niue", code: "+683" },
    { label: "جزيرة نورفوك (+672)", value: "Norfolk Island", code: "+672" },
    {
      label: "جزر ماريانا الشمالية (+1670)",
      value: "Northern Mariana Islands",
      code: "+1670",
    },
    { label: "النرويج (+47)", value: "Norway", code: "+47" },
    { label: "سلطنة عمان (+968)", value: "Oman", code: "+968" },
    { label: "باكستان (+92)", value: "Pakistan", code: "+92" },
    { label: "بالاو (+680)", value: "Palau", code: "+680" },
    { label: "فلسطين (+970)", value: "Palestinian Territories", code: "+970" },
    { label: "بنما (+507)", value: "Panama", code: "+507" },
    {
      label: "بابوا غينيا الجديدة (+675)",
      value: "Papua New Guinea",
      code: "+675",
    },
    { label: "باراغواي (+595)", value: "Paraguay", code: "+595" },
    { label: "بيرو (+51)", value: "Peru", code: "+51" },
    { label: "الفلبين (+63)", value: "Philippines", code: "+63" },
    { label: "بيتكيرن (+64)", value: "Pitcairn Islands", code: "+64" },
    { label: "بولندا (+48)", value: "Poland", code: "+48" },
    { label: "البرتغال (+351)", value: "Portugal", code: "+351" },
    { label: "بورتو ريكو (+1939)", value: "Puerto Rico", code: "+1939" },
    { label: "قطر (+974)", value: "Qatar", code: "+974" },
    { label: "رومانيا (+40)", value: "Romania", code: "+40" },
    { label: "روسيا (+7)", value: "Russia", code: "+7" },
    { label: "رواندا (+250)", value: "Rwanda", code: "+250" },
    { label: "سان بارتليمي (+590)", value: "Saint Barthelemy", code: "+590" },
    { label: "سانت هيلانة (+290)", value: "Saint Helena", code: "+290" },
    {
      label: "سانت كيتس ونيفيس (+1869)",
      value: "Saint Kitts and Nevis",
      code: "+1869",
    },
    { label: "سانت لوسيا (+1758)", value: "Saint Lucia", code: "+1758" },
    { label: "سان مارتن (+590)", value: "Saint Martin", code: "+590" },
    {
      label: "سان بيير وميكولون (+508)",
      value: "Saint Pierre and Miquelon",
      code: "+508",
    },
    {
      label: "سانت فينسنت وجزر غرينادين (+1784)",
      value: "Saint Vincent and the Grenadines",
      code: "+1784",
    },
    { label: "ساموا (+685)", value: "Samoa", code: "+685" },
    { label: "سان مارينو (+378)", value: "San Marino", code: "+378" },
    {
      label: "ساو تومي وبرينسيبي (+239)",
      value: "Sao Tome and Principe",
      code: "+239",
    },
    {
      label: "المملكة العربية السعودية (+966)",
      value: "Saudi Arabia",
      code: "+966",
    },
    { label: "السنغال (+221)", value: "Senegal", code: "+221" },
    { label: "صربيا (+381)", value: "Serbia", code: "+381" },
    { label: "سيشل (+248)", value: "Seychelles", code: "+248" },
    { label: "سيرا ليون (+232)", value: "Sierra Leone", code: "+232" },
    { label: "سنغافورة (+65)", value: "Singapore", code: "+65" },
    { label: "سلوفاكيا (+421)", value: "Slovakia", code: "+421" },
    { label: "سلوفينيا (+386)", value: "Slovenia", code: "+386" },
    { label: "جزر سليمان (+677)", value: "Solomon Islands", code: "+677" },
    { label: "الصومال (+252)", value: "Somalia", code: "+252" },
    { label: "جنوب أفريقيا (+27)", value: "South Africa", code: "+27" },
    { label: "جنوب السودان (+211)", value: "South Sudan", code: "+211" },
    { label: "إسبانيا (+34)", value: "Spain", code: "+34" },
    { label: "سريلانكا (+94)", value: "Sri Lanka", code: "+94" },
    { label: "السودان (+249)", value: "Sudan", code: "+249" },
    { label: "سورينام (+597)", value: "Suriname", code: "+597" },
    { label: "سوازيلاند (+268)", value: "Swaziland", code: "+268" },
    { label: "السويد (+46)", value: "Sweden", code: "+46" },
    { label: "سويسرا (+41)", value: "Switzerland", code: "+41" },
    { label: "سوريا (+963)", value: "Syria", code: "+963" },
    { label: "تايوان (+886)", value: "Taiwan", code: "+886" },
    { label: "طاجيكستان (+992)", value: "Tajikistan", code: "+992" },
    { label: "تنزانيا (+255)", value: "Tanzania", code: "+255" },
    { label: "تايلاند (+66)", value: "Thailand", code: "+66" },
    { label: "توغو (+228)", value: "Togo", code: "+228" },
    { label: "توكيلاو (+690)", value: "Tokelau", code: "+690" },
    { label: "تونغا (+676)", value: "Tonga", code: "+676" },
    {
      label: "ترينيداد وتوباغو (+1868)",
      value: "Trinidad and Tobago",
      code: "+1868",
    },
    { label: "تونس (+216)", value: "Tunisia", code: "+216" },
    { label: "تركيا (+90)", value: "Turkey", code: "+90" },
    { label: "تركمانستان (+993)", value: "Turkmenistan", code: "+993" },
    {
      label: "جزر توركس وكايكوس (+1649)",
      value: "Turks & Caicos Islands",
      code: "+1649",
    },
    { label: "توفالو (+688)", value: "Tuvalu", code: "+688" },
    { label: "أوغندا (+256)", value: "Uganda", code: "+256" },
    { label: "أوكرانيا (+380)", value: "Ukraine", code: "+380" },
    {
      label: "الإمارات العربية المتحدة (+971)",
      value: "United Arab Emirates",
      code: "+971",
    },
    { label: "المملكة المتحدة (+44)", value: "United Kingdom", code: "+44" },
    { label: "الولايات المتحدة (+1)", value: "United States", code: "+1" },
    { label: "أوروغواي (+598)", value: "Uruguay", code: "+598" },
    { label: "أوزباكستان (+998)", value: "Uzbekistan", code: "+998" },
    { label: "فانواتو (+678)", value: "Vanuatu", code: "+678" },
    { label: "الفاتيكان (+379)", value: "Vatican City", code: "+379" },
    { label: "فنزويلا (+58)", value: "Venezuela", code: "+58" },
    { label: "فيتنام (+84)", value: "Vietnam", code: "+84" },
    {
      label: "جزر العذراء البريطانية (+1284)",
      value: "Virgin Islands - British",
      code: "+1284",
    },
    {
      label: "جزر العذراء الأمريكية (+1340)",
      value: "Virgin Islands - US",
      code: "+1340",
    },
    { label: "والس وفوتونا (+681)", value: "Wallis & Futuna", code: "+681" },
    { label: "اليمن (+969)", value: "Yemen (North)(+969)", code: "+969" },
    { label: "اليمن (+967)", value: "Yemen (South)(+967)", code: "+967" },
    { label: "زامبيا (+260)", value: "Zambia", code: "+260" },
    { label: "زيمبابوي (+263)", value: "Zimbabwe", code: "+263" },
  ];
  return (
    <StyledSelect
      options={options}
      values={[options[0]]}
      color="#f6f5e3"
      dropdownGap={0}
      onChange={(value) => setCountryCode(value[0].code)}
      required
    />
  );
}
const StyledSelect = styled(Select)`
  background: #f6f5e3;
  border: #014e4e !important;
  color: #014e4e;

  border-radius: 5px;
  height: 40px;

  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: #014e4e;
  }

  .react-dropdown-select-option {
    border: 1px solid #014e4e;
  }

  .react-dropdown-select-item {
    color: #014e4e;
  }

  .react-dropdown-select-input {
    color: #014e4e;
  }

  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 200px;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    background: #;
    box-shadow: none;
    color: #014e4e !important;
    background: #f6f5e3;
  }

  .react-dropdown-select-item {
    color: #014e4e;
    background: #f6f5e3;
    border-bottom: 1px solid #333;

    :hover {
      color: #f6f5e3 !important;
      background: #014e4e;
    }
  }

  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #014e4e;
    border-bottom: 1px solid #014e4e;
    color: #014e4e;
    font-weight: bold;
  }

  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #f6f5e3;
    color: #014e4e;
  }
`;

export default PhoneInput;
