import {
  Sparkle,
  HandsClapping,
  Handshake,
  Target,
  Chat,
  Heart,
  Eye,
  ChalkboardTeacher,
  UsersFour,
  Smiley 
} from "phosphor-react";
function AboutUs() {
  return (
    <section id="about-us" className="py-5">
      <div className="container">
        <h2 className="display-5 mb-4">من نحن ؟</h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5 mb-4  d-flex align-items-center justify-content-center ">
              <div className="about-us-info  ">
                <h3 className="mb-5 display-4">
                  {" "}
                  رسالتنا <Chat size={62} />
                </h3>
                <p className="lead">
                  تعليم القرآن بمفهومه الواسع و الذي يشمل تعليم أحكام التلاوة و
                  الترتيل و التحفيظ و التفسير والتدبر وصولا الي العمل: وذلك
                  بأفضل الوسائل التربوية و أحسن الطرق التعليمية
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mb-4 ">
              <div className="about-us-image  text-center  ">
                <picture>
                  <source srcSet="./images/quoran.webp" type="image/webp" />
                  <source srcSet="./images/quoran.jpg" type="image/jpg" />
                </picture>

                <img
                  src="./images/03.jpg"
                  alt="collections of quran"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="row second-row">
            <div className="col-lg-6 mt-5 mb-4  d-flex align-items-center justify-content-center">
              <div className="about-us-image  text-center  ">
                <picture>
                  <source srcSet="./images/men.webp" type="image/webp" />
                  <source srcSet="./images/men.jpg" type="image/jpg" />
                </picture>
                <img
                  src="./images/03.jpg"
                  alt="men reading quran"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6 mt-5 mb-4 d-flex align-items-center justify-content-center ">
              <div className="about-us-info  ">
                <h3 className="mb-4 display-4 me-4">
                  قيمنا <Sparkle size={62} />
                </h3>
                {/* <ul className="list-list-unstyled">
                  <li className="mb-4  lead ">
                    {" "}
                    <Sparkle
                      size={25}
                      className="ms-2 "
                      color="#F6F5E3"
                      weight="light"
                    />{" "}
                    <b className="h4">التميز :</b> نسعي دائمًا لتقديم تجربه تعلم
                    استثنائيه و مميزة .
                  </li>
                  <li className="mb-4 lead">
                    {" "}
                    <UsersFour
                      className="ms-2 "
                      size={25}
                      color="#F6F5E3"
                      weight="light"
                    />{" "}
                    <b className="h4">التعاون :</b> نسعي دائمًا لتقديم تجربه
                    تعلم استثنائيه و مميزة نعتبر التعاون مع مجتمع الطلاب أمرًا
                    أساسيًا لتحقيق التقدم في رحلة حفظ القرآن.
                  </li>
                  <li className="mb-4 lead">
                    
                    <Screencast
                      className="ms-2 "
                      size={25}
                      color="#F6F5E3"
                      weight="light"
                    />
                    <b className="h4">التفاعلية :</b> نعزز التفاعل والمشاركة من
                    خلال منصة تعلم فعّالة ومحفزة.
                  </li>
                  <li className="mb-4 lead">
                    
                    <CheckCircle
                      className="ms-2 "
                      size={25}
                      color="#F6F5E3"
                      weight="light"
                    />
                    <b className="h4">الشفافيه :</b> نحن نلتزم بالشفافيه وتقديم
                    محتوي موثوف ودقيق.
                  </li>
                </ul> */}
                <div className="d-flex">
                  <Sparkle
                    size={30}
                    className="ms-2 "
                    color="#F6F5E3"
                    weight="light"
                  />{" "}
                  <p className="lead">
                    <b className="h4">الإتقان :</b> نلتزم بتقديم بيئة دراسية
                    تهدف إلى الإتقان في حفظ القرآن الكريم.
                  </p>
                </div>
                <div className="d-flex">
                  <Target
                    size={55}
                    className="ms-2 "
                    color="#F6F5E3"
                    weight="light"
                  />{" "}
                  <p className="lead">
                    <b className="h4">الإنجاز :</b> سعينا الدائم نحو تقدم ملحوظ
                    في رحلة حفظ القرآن، حيث نحثّ على تحقيق أهدافك وإتمام هذا
                    الإنجاز العظيم بروح إلهام واجتهاد دائم.
                  </p>
                </div>
                <div className="d-flex">
                  <HandsClapping
                    size={40}
                    className="ms-2 "
                    color="#F6F5E3"
                    weight="light"
                  />
                  <p className="lead">
                    <b className="h4">التحفيز :</b> نشجع ونلهم طلابنا لتحقيق
                    تقدم مستمر في حفظ القرآن من خلال دعم وتحفيز مستمر.
                  </p>
                </div>
                <div className="d-flex">
                  <Handshake
                    size={45}
                    className="ms-2 "
                    color="#F6F5E3"
                    weight="light"
                  />
                  <p className="lead">
                    <b className="h4">التيسير :</b> نسعي دائما لتيسير فهم وحفظ
                    القران الكريم بجميع الوسائل الممكنه لطالبنا .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6 mt-5 mb-4  d-flex align-items-center justify-content-center ">
              <div className="about-us-info  ">
                <h3 className="mb-4 display-4 me-4">
                  {" "}
                  اهدافنا <Target size={62} />{" "}
                </h3>
                <div className="d-flex">
                  <Sparkle
                    size={35}
                    className="ms-2  "
                    color="#F6F5E3"
                    weight="light"
                  />{" "}
                  <p className="lead  ">
                    التطلع لأن تكون الأكاديمية صرحا قرآنيا نموذجيا رائد في كل
                    المجالات المتعلقه بتعليم القرآن الكريم.
                  </p>
                </div>
                <div className="d-flex">
           
                  <UsersFour size={35}
                    className="ms-2  "
                    color="#F6F5E3"
                    weight="light" />
                  <p className="lead  ">
                    إيجاد البيئة القرآنية المشجعة الجاذبة للطلاب و الطالبات و
                    المعلمين و المعلمات و المشرفين و المشرفات.
                  </p>
                </div>
                <div className="d-flex">
                
                  <ChalkboardTeacher size={25}
                    className="ms-2  "
                    color="#F6F5E3"
                    weight="light" />
                  <p className="lead  ">
                    توفير و توظيف احدث الوسائل التقنية التعليمية الحديثة.
                  </p>
                </div>
                <div className="d-flex">
           
                  <Handshake size={35}
                    className="ms-2  "
                    color="#F6F5E3"
                    weight="light" />
                  <p className="lead  ">
                    التطلع للخيرية الموعود بها في قوله صلي الله عليه وسلم (خيركم
                    من تعلم القرآن وعلمه).
                  </p>
                </div>
                <div className="d-flex">
             
                  <Smiley  size={25}
                    className="ms-2  "
                    color="#F6F5E3"
                    weight="light" />
                  <p className="lead  ">
                    تخريج شخصيات قرآنية متخلقين بأخلاق القرآن الكريم في كل عام
                  </p>
                </div>
                <div className="d-flex">
                
                  <Heart size={25}
                    className="ms-2  "
                    color="#F6F5E3"
                    weight="light" />
                  <p className="lead  ">غرس محبة القرآن في النفوس.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mb-4  d-flex align-items-center justify-content-center  ">
              <div className="about-us-image text-center  ">
                <picture>
                  <source srcSet="./images/women.webp" type="image/webp" />
                  <source srcSet="./images/women.jpg" type="image/jpg" />
                </picture>
                <img
                  src="./images/03.jpg"
                  alt="women holding quran"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="row second-row">
            <div className="col-lg-6 mt-5 mb-4 ">
              <div className="about-us-image  text-center  ">
                <picture>
                  <source srcSet="./images/men.webp" type="image/webp" />
                  <source srcSet="./images/men.jpg" type="image/jpg" />
                </picture>
                <img
                  src="./images/03.jpg"
                  alt="men reading quran"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6 mt-5 mb-4 d-flex align-items-center justify-content-center ">
              <div className="about-us-info  ">
                <h3 className="mb-5 display-4">
                  رؤيتنا <Eye size={62} />
                </h3>
                <p className="lead">
                  الرقي و التميز في تعليم القرآن الكريم , و الإبداع في غرس قيمه
                  في جميع شرائح المجتمع بكوادر متميزة وبرامج نوعية وبيئة محفزة
                  بتقنية حديثة .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
