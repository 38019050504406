import NavBar from "./navbar";
import { WhatsappLogo } from "phosphor-react";

function Hero() {
  return (
    <header id="hero-section" className="vh-100">
      <div className="layer">
        <NavBar />
        <div className=" d-flex align-items-center justify-content-center  hero-header">
          <div className="hero-content ">
            <h1 className="mb-4 ">
            أكاديمية السفره للتعليم عن بعد
               
            </h1>
            <a className="hero-btn" href="#subscription-form">إشترك الآن</a>
      
          </div>
        </div>
      </div>

      <a href="https://chat.whatsapp.com/HGDxYcjRW2M6OjsBDQDQw9" aria-label="a whatsapp link about safara  ">
        <button className="whats-btn " aria-label="whats app button">
          <WhatsappLogo size={40} color="#014e4e" />
        </button>
      </a>
    </header>
  );
}

export default Hero;
