import { FacebookLogo, TwitterLogo, InstagramLogo } from "phosphor-react";
function Footer() {
  return (
    <footer id="footer-section" className="py-5 ">
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-4 d-flex align-items-center justify-content-center  ">
            <div className="footer-second-side    ">
              <div className="social-media   m-auto">
                <ul className="d-flex  align-items-center social-ul">
                  <li>
                    <FacebookLogo size={40} color="#f6f5e3" weight="light" />
                  </li>
                  <li>
                    <InstagramLogo size={40} color="#f6f5e3" weight="light" />
                  </li>
                  <li>
                    <TwitterLogo size={40} color="#f6f5e3" weight="light" />
                  </li>
                </ul>
              </div>
            
            </div>
          </div>
          <div className="col-md-4  text-center">
            <div className="footer-logo">
              <img src="./images/logo.png" alt="el-safra logo" />
            </div>
          </div>
   
          <div className="col-md-4  d-flex justify-content-center align-items-center">
            <div className="footer-first-side">
              <div className="footer-copy-write">
                <p>حقوق الطبع والنشر © 2024 - السفره</p>
                <p>البريد الإلكتروني:hsnhy1254@gmail.com</p>
                <p>رقم الهاتف : 01556367118+</p>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </footer>
  );
}

export default Footer;
