import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import PhoneInput from "./phoneInput";
import { useState } from "react";
// import axios from "axios";
function SubscriptionForm() {
  const [contryCode, setCountryCode] = useState("");
  const [countryCodeErrorMsg , setCountryCodeErrorMsg ] = useState(false);
  const [submitSuccess , setSubmitSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  //    const onSubmit = (data) =>
  //   {
  //     const dataObj = { ...data, contryCode };
  //    if(!contryCode)
  //      {
  //       setCountryCodeErrorMsg(true);
  //        return;
  //     }
  //      setCountryCodeErrorMsg(false);
  //      setSubmitSuccess(true);
  //     console.log(dataObj)
  //  } ;

  const onSubmit = async (data) => {
    console.log("on submit data:", data);
    // Handle API submission here
    try {
      if (!contryCode) {
        setCountryCodeErrorMsg(true);
        return;
      }
      setCountryCodeErrorMsg(false);
      const response = await fetch(
        "https://alsafarah.onrender.com/api/payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify({ ...data, contryCode }),
        }
      );

      const responseData = await response.json();

      const paymentUrl= responseData.data.paymentUrl;
      window.location.href = paymentUrl;
      setSubmitSuccess(true);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    console.log({...data, contryCode});
  };

  // const onSubmit = async (data) => {
  //   const dataObj = { ...data, contryCode };
  //   try {
  //     if (!contryCode) {
  //       setCountryCodeErrorMsg(true);
  //       return;
  //     }
  //     setCountryCodeErrorMsg(false);
  //     const response = await axios.post('https://alsafarah.onrender.com/api/clients', dataObj);
  //     console.log('API response:', response.data);
  //     setSubmitSuccess(true);
  //   } catch (error) {
  //     console.error('Error submitting data:', error);
  //     setError('username', {
  //       type: 'manual',
  //       message: 'Failed to submit. Please try again.',
  //     });
  //   }
  // };

  return (
    <section id="subscription-form" className="py-5">
      <div className="container">
        <h2 className="display-5"> إشترك الأن </h2>
        <div className="container">
          <div className="form-content  mt-5">
            <div className="row form-direction">
              <div className="col-md-8 p-5  form-bg">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-names d-flex">
                    <Form.Group
                      className="mb-3 first width-100  w-50 m-2"
                      controlId="formBasicFirstName"
                    >
                      <Form.Label className="h5 mb-3 ">إسمك الأول </Form.Label>
                      <Form.Control
                        {...register("firstName", {
                          required: "يجب عليك ادخال إسمك الأول ",
                          pattern: {
                            value: /^[A-Za-z\u0600-\u06FF]+$/i,
                            message: "يجب أن يكون الإسم حروف فقط",
                          },
                          maxLength: {
                            value: 10,
                            message: "أقصى عدد 10 أحرف",
                          },
                        })}
                        type="text"
                        placeholder="أدخل إسمك الأول"
                      />
                      {errors.firstName && (
                        <p className="error-bg">{errors.firstName.message}</p>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="mb-3 width-100 w-50 m-2"
                      controlId="formBasicLastName"
                    >
                      <Form.Label className="h5 mb-3">إسمك الأخير </Form.Label>
                      <Form.Control
                        {...register("lastName", {
                          required: "يجب عليك ادخال إسمك الأخير ",
                          pattern: {
                            value: /^[A-Za-z\u0600-\u06FF]+$/i,
                            message: "يجب أن يكون الإسم حروف فقط",
                          },
                          maxLength: {
                            value: 10,
                            message: "أقصى عدد 10 أحرف",
                          },
                        })}
                        type="text"
                        placeholder="أدخل إسمك الأخير"
                      />
                      {errors.lastName && (
                        <p className="error-bg">{errors.lastName.message}</p>
                      )}
                    </Form.Group>
                  </div>


                 

                  <div className="d-flex form-second-pairs  ">
                    <Form.Group
                      className="mt-2  w-100"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="h5 mb-3">
                        البريد الإلكتروني
                      </Form.Label>
                      <Form.Control
                        {...register("email", {
                          required: "يجب عليك ادخال  بريدك الإلكتروني ",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "يجب أن يكون البريد الإلكتروني صحيح",
                          },
                        })}
                        type="email"
                        placeholder="أدخل بريدك الإلكتروني "
                      />
                      {errors.email && (
                        <p className="error-bg">{errors.email.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 width-100 w-25 m-2 "
                      controlId="formBasicAge"
                    >
                      <Form.Label className="h5 mb-3">العمر</Form.Label>
                      <Form.Control
                        {...register("age", {
                          required: "  ادخل عمرك  ",
                          pattern: {
                            value: /^(?:[1-9][0-9]?|1[01][0-9]|120)$/,
                            message: "يجب أن يكون العمر صحيح    ",
                          },
                          maxLength: {
                            value: 3,
                            message: "أقصى عدد 3 أرقام ",
                          },
                        })}
                        type="number"
                        placeholder="أدخل عمرك   "
                      />
                      {errors.age && (
                        <p className="error-bg">{errors.age.message}</p>
                      )}
                    </Form.Group>
                  </div>

                  <div className="d-flex form-fourth-pairs">
                  <Form.Group
      className="mb-3 w-100 width-100 m-2"
      controlId="formBasicCountry"
    >
      <Form.Label className="h5 mb-3"> رقم المحمول </Form.Label>
      <Form.Control
        {...register("phoneNumber", {
          required: "يجب عليك ادخال رقم المحمول ",
          pattern: {
            value: /^[0-9]\d{9,11}$/,
            message:
              " يجب ان يكون رقم المحمول من 10 ارقام الي 12 رقم بحد اقصي ",
          },
        })}
        type="text"  
        placeholder="أدخل رقم المحمول "
        onChange={(e) => {
          // Remove leading zero before setting the value to the state
          const valueWithoutLeadingZero = e.target.value.replace(/^0+/, '');
          setValue('phoneNumber', valueWithoutLeadingZero);
        }}
      />
      {errors.phoneNumber && (
        <p className="error-bg">{errors.phoneNumber.message}</p>
      )}
    </Form.Group>
                    <Form.Group
                      className="mb-3 w-100 width-100 m-2 "
                      controlId="formBasicCountryCode"
                    >
                      <Form.Label className="h5 mb-3"> كود البلد </Form.Label>
                      <PhoneInput
                        register={register}
                        setCountryCode={setCountryCode}
                      />
                      {countryCodeErrorMsg && (
                        <p className="error-bg">أدخل كود البلد</p>
                      )}
                    </Form.Group>
                  </div>

                  <div className="d-flex form-third-pairs">
                    <Form.Group
                      className="mb-3 width-100 w-50 m-2"
                      controlId="formBasicPackage"
                    >
                      <Form.Label className="h5 mb-3">
                         باقتك الشهرية
                      </Form.Label>
                      <Form.Select
                        {...register("planID", {
                          required: "يجب عليك إختيار باقه شهرية",
                        })}
                        aria-label="Default select example"
                      >
                        <option value="1">الباقات الشهرية</option>
                        <option value="2">الباقة الإبتدائية</option>
                        <option value="3">الباقة المتميزة</option>
                        <option value="4">الباقة النخبوية</option>
                      </Form.Select>
                      {errors.planID && (
                        <p className="error-bg">{errors.planID.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 width-100 w-50 m-2"
                      controlId="formBasicCountry"
                    >
                      <Form.Label className="h5 mb-3"> البلد </Form.Label>
                      <Form.Control
                        {...register("country", {
                          required: "يجب عليك ادخال بلدك  ",
                          pattern: {
                            value: /^[A-Za-z\u0600-\u06FF]+$/i,
                            message: "يجب أن يكون إسم البلد حروف فقط",
                          },
                          maxLength: {
                            value: 10,
                            message: "أقصى عدد 10 أحرف",
                          },
                        })}
                        type="text"
                        placeholder="أدخل بلدك "
                      />
                      {errors.country && (
                        <p className="error-bg">{errors.country.message}</p>
                      )}
                    </Form.Group>
                  </div>

                  <Button
                    variant="primary"
                    type="submit"
                    className="form-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "جاري الإرسال ..." : "إشترك الأن"}
                  </Button>
                  {submitSuccess && (
                    <p style={{ color: "green" }}>
                      {" "}
                      لقد تم إرسال الإستماره بنجاح
                    </p>
                  )}
                </Form>
              </div>
              <div
                className="col-md-4  bg-img position-relative "
                role="img"
                aria-label="a girl looking to a screen have a safrah logo "
              >
                {/* <div className="layer form-layer"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionForm;
